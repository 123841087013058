// eslint-disable-next-line class-methods-use-this
import React from 'react';

const getBlankMessage = keywords => (
  <>
    <h6>{`Sorry, there are no results for "${keywords}"`}</h6>
    <p>
      Try searching again or go
      {' '}
      <a href="/">back to home</a>
    </p>
  </>
);

export {
  getBlankMessage,
};
