import React from 'react';
import get from 'lodash.get';
import { useQuery, useApolloClient } from '@apollo/client';
import ProductSelectorQuery from 'GraphQLFragments/ProductSelectorQuery.graphql';

const withProductSelector = (Template) => {
  const WrappedTemplate = (props) => {
    const client = useApolloClient();
    const { loading, data } = useQuery(ProductSelectorQuery, {
      ssr: false,
      client,
    });

    const productData = get(data, 'getSettings.productData', []);
    const makeModelData = loading ? null : productData;

    // Prop `inputs` is come from the prop naming in "ProductSelector" Component, for compatibility.
    return (
      <Template {...props} inputs={makeModelData} makeModelData={makeModelData} />
    );
  };

  WrappedTemplate.displayName = 'withProductSelector()';

  return WrappedTemplate;
};


export default withProductSelector;
