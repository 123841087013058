import React from 'react';

const withJsonLD = Helmet => (Component) => {
  const BaseComponentWithHelmet = props => (
    <>
      <Helmet {...props} />
      <Component {...props} />
    </>
  );

  withJsonLD.displayName = 'withHelmet()';

  return BaseComponentWithHelmet;
};

export default withJsonLD;
