/**
 * Get review page URL from spec page
 *
 * @param {*} url
 * @param {*} specTemplate
 *
 *  @todo Retrieve URL from middleware when possible and not rely on hardcoded value for pricesSpecsSlug.
 */
export const getReviewURLFromSpecPage = (url, specTemplate) => {
  const pricesSpecsSlug = '/prices-specs';
  let reviewURLFromSpecPage = url.substring(0, url.indexOf(pricesSpecsSlug));
  if (specTemplate === 'specs') {
    reviewURLFromSpecPage += pricesSpecsSlug;
  }

  return reviewURLFromSpecPage;
};
