import React from 'react';
import { compose } from 'recompose';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForHome';
import { withPreloadPrimaryImage } from 'Protons';
import { BaseComponent } from 'Templates';

import { Home } from './Home';

const ComposedHome = compose(
  withPolaris,
  withPreloadPrimaryImage,
)(Home);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <ComposedHome {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
