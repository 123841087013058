import React, { useLayoutEffect } from 'react';
import { Helmet } from 'polaris-coreweb/exports';
import { getSocialEmbedScript } from '@autovia-uk/polaris-components/sharedHelpers/getSocialEmbedScript';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { getComponentBlocks } from './getComponentBlocks';

const setWindowVariables = (embedTypes) => {
  embedTypes.forEach((type) => {
    if (type === 'Facebook' && window.FB) {
      window.FB.XFBML.parse();
    } else if (type === 'Twitter' && window.twttr) {
      window.twttr.widgets.load();
    } else if (type === 'Instagram' && window.instgrm) {
      window.instgrm.Embeds.process();
    }
  });
};

const Body = (props) => {
  const embedTypes = new Set();
  const blocks = getComponentBlocks(props, embedTypes);

  if (embedTypes.size && typeof window !== 'undefined') {
    useLayoutEffect(() => setWindowVariables(embedTypes));
  }

  return (
    <>
      <Helmet>
        {getSocialEmbedScript(embedTypes)}
      </Helmet>
      {blocks}
    </>
  );
};

export default withPolaris(Body);
