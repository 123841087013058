// This filters defined and hardcoded in middleware
// https://github.com/autovia-uk/polaris-middleware/blob/release/src/appsync/schema.graphql#L724-L731
export const getFilters = () => [
  {
    label: 'All',
    id: 'ALL',
  },
  {
    label: 'News',
    id: 'NEWS',
  },
  {
    label: 'Reviews',
    id: 'REVIEWS',
  },
  {
    label: 'Features',
    id: 'FEATURES',
  },
  {
    label: 'Advice',
    id: 'TIPS',
  },
];
