import PropTypes from 'prop-types';
import {
  breakpointsShape, configPartialShape, globalSettingsShape, layoutDataShape,
} from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    ...configPartialShape,
    ...breakpointsShape,
    ...globalSettingsShape,
  }).isRequired,
};

export const reviewPropsShape = {
  ...configShape,
  ...layoutDataShape,
};
