import React from 'react';
import { BaseComponent } from 'Templates';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForSpec';
import { SpecTemplate } from './SpecTemplate';

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <SpecTemplate {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
