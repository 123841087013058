import React from 'react';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForSpecList';
import { BaseComponent } from 'Templates';
import { SpecListTemplate } from './SpecListTemplate';

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <SpecListTemplate {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
