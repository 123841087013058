import PropTypes from 'prop-types';
import { breakpointsShape, globalSettingsShape, layoutDataShape } from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    ...breakpointsShape,
    siteUrl: PropTypes.string.isRequired,
    newsletter: PropTypes.object,
    ...globalSettingsShape,
  }).isRequired,
};

export const basicPagePropsShape = {
  ...configShape,
  ...layoutDataShape,
};
