import React from 'react';
import { Error } from '@autovia-uk/polaris-components/components/molecules/Error';
import { BaseComponent } from 'Templates';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForErrorPage';

import 'Styles/components/templates/_Error.scss';

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <Error {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
