import PropTypes from 'prop-types';
import {
  configPartialShape, layoutDataShape,
} from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    ...configPartialShape,
  }).isRequired,
};

export const specListShape = {
  ...configShape,
  ...layoutDataShape,
};
