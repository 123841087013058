import get from 'lodash.get';

export const getMappedLayoutType = (props) => {
  const layoutType = get(props, 'layoutData.page.layoutType', '');

  switch (layoutType) {
    case 'Full Width':
      return 'fullWidth';
    case 'Default':
      return 'default';
    case 'Landing Page':
    case 'landingPage':
      return 'landingPage';
    case 'Commercial Page':
    case 'commercialPage':
      return 'commercialPage';
    case 'Commercial Page Title Image':
    case 'commercialPageTitleImage':
      return 'commercialPageTitleImage';
    default:
      return 'default';
  }
};
