import { getNavigationConfig } from './getNavigationConfig';

export const getFooterConfig = (config) => {
  if (!config) {
    return {};
  }

  const navigation = getNavigationConfig(config);
  const footerNavigation = navigation && navigation.footer;
  const { newsletterConfig } = config;

  return {
    logo: {
      src: config.branding.logo.src,
      height: config.branding.logo.height,
      width: config.branding.logo.width,
      url: config.branding.logo.url,
      alt: config.branding.logo.alt,
    },
    secondaryLogo: {
      src: config.branding.logo.src,
      height: config.branding.logo.height,
      width: config.branding.logo.width,
      url: config.branding.logo.url,
    },
    navigation: footerNavigation,
    newsletterConfig,
  };
};
