import React from 'react';
import { compose } from 'recompose';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForGallery';
import { BaseComponent } from 'Templates';
import { withPreloadPrimaryImage } from 'Protons';

import { Gallery } from './Gallery';

const Composed = compose(
  withPolaris,
  withPreloadPrimaryImage,
)(Gallery);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
