import React from 'react';
import { PostMeta } from '@autovia-uk/polaris-components/components/molecules/PostMeta';
import { getAuthors } from './getAuthors';

export const getPostMeta = (page) => {
  const {
    date,
    authors,
    authorPrefix = 'by ',
    layoutType,
  } = page;

  let filteredAuthors = Array.isArray(authors) ? authors.map(author => ({ ...author })) : [];

  if (layoutType === 'commercialPage' || layoutType === 'commercialPageTitleImage') {
    filteredAuthors = false;
  }

  return (
    <PostMeta
      date={date}
      authorPrefix={!(layoutType === 'commercialPage' || layoutType === 'commercialPageTitleImage') ? authorPrefix : ''}
      {...getAuthors(filteredAuthors)}
    />
  );
};
