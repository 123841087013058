import PropTypes from 'prop-types';
import { globalSettingsShape, layoutDataShape, paginationShape } from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    newsletter: PropTypes.object,
    ...globalSettingsShape,
  }).isRequired,
};

export const authorPropsShape = {
  ...configShape,
  ...layoutDataShape,
  ...paginationShape,
};
