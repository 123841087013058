import React from 'react';
import { compose, withState, withProps } from 'recompose';
import { withPagination } from 'polaris-coreweb/exports';
import { BaseComponent } from 'Templates';
import { withJsonLD } from 'Protons';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForSearch';
import { SearchTemplate } from './SearchTemplate';
import { SearchQueryPagination as query } from './SearchQuery.graphql';

const Composed = compose(
  withState(
    'searchPath',
    'setSearchPath',
    ({ location: { pathname } }) => pathname,
  ),
  withProps(({ searchPath }) => ({ location: { pathname: searchPath } })),
  withPagination({ query }),
  withJsonLD(Helmet),
)(SearchTemplate);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
