import React from 'react';
import { BaseComponent } from 'Templates';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForPage';
import { BasicPage } from './BasicPage';

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <BasicPage {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
