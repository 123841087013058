import PropTypes from 'prop-types';
import { globalSettingsShape, metaDataShape, dataLayerShape } from 'Helpers/props-shapes';

export const basePropsShape = {
  config: PropTypes.shape({
    ...globalSettingsShape,
  }),
  adConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  ...metaDataShape,
  ...dataLayerShape,
};
